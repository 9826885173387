<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("MENU.ITEM.SHOPS.SHOPS") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <router-link
            v-permission="['shops-store']"
            to="add"
            class="btn btn-light-success font-weight-bolder"
            ><i class="flaticon2-plus"></i>
            {{ $t("LABELS.ADD_NEW") }}</router-link
          >
        </div>
      </template>
      <v-card>
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
          <template v-slot:prepend> </template>
        </SearchTools>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingShop"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="px-6 elevation-1"
        >
          <template v-slot:[`item.active`]="{ item }">
            <v-chip :color="getColor(item.active)" small dark pill>
              <v-avatar left>
                <v-icon>{{ iconIsActive(item.active) }}</v-icon>
              </v-avatar>
              {{ textIsActive(item.active) }}</v-chip
            >
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['shops-update'])"
              :to="{ name: 'edit-shop', params: { id: item.id } }"
            >
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-2" fab dark x-small v-bind="attrs" v-on="on">
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("LABELS.EDIT") }}</span>
              </v-tooltip>
            </router-link>
            <template v-else>Няма права</template>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";
import SearchTools from "@/core/components/table/SearchTools.vue";

//Page Module
import { FETCH_SHOPS } from "@/modules/shop/store/shop.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import { dataTableMixin } from "@/core/mixins/dataTableMixin";

export default {
  mixins: [permissionMixin, dataTableMixin],
  components: {
    SearchTools
  },
  data() {
    return {
      method: FETCH_SHOPS,
      headers: [
        {
          text: "ID",
          align: "left",
          value: "id"
        },
        { text: "Име", value: "name" },
        { text: "Линк", value: "url" },
        { text: "Нов", value: "is_new" },
        { text: "Активен", value: "active" },
        {
          align: "right",
          text: "Опции",
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["isLoadingShop"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Магазини", route: { name: "list-shops" } },
      { title: "Списък магазини" }
    ]);
  }
};
</script>
